import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl, Typography, useMediaQuery, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../services/settings';

import * as XLSX from 'xlsx';

const BillingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [clearedDebits, setClearedDebits] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]); // Track tenants for filtering
  const userRole = localStorage.getItem('role');
  const [billingSummary, setBillingSummary] = useState([]);


  const navigate = useNavigate(); // Use navigate for navigation
  
  useEffect(() => {
    checkRole();
 }, []);

 const checkRole = async () => {
  const isAdmin = localStorage.getItem('role');
if (isAdmin !== 'ADMIN') {
  navigate('/dashboard')
}
 }


  const sortedDebits = [...clearedDebits].sort((a, b) => {
    let direction = 'asc';
    const valueA = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
    const valueB = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

    if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
   
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }
    return 0;
  });
  const filteredDebits = sortedDebits.filter(debit => {
    const userId = debit?.user_id ? debit.user_id.toString().toLowerCase() : '';
    const email = debit?.email ? debit.email.toLowerCase() : '';
    const tenantName = debit?.tenantName ? debit.tenantName.toLowerCase() : '';
  
    return (
      (email.includes(searchQuery.toLowerCase()) ||
      userId.includes(searchQuery.toLowerCase())) &&
      (selectedTenant === '' || tenantName === selectedTenant.toLowerCase()) // Apply tenant filter
    );
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (userId) => {
    navigate(`/users/${userId}`);
  };

  useEffect(() => {
    fetchEOMdebits();
  }, []); // Fetch debits when tenant filter changes

  useEffect(() => {
    if (clearedDebits.length > 0) {
      const summaries = calculateBillingSummary(clearedDebits);
      setBillingSummary(summaries);
    }
  }, [clearedDebits]);

  const fetchEOMdebits = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.post(api.eomBillingReporting, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
        body: { tenantName: headers.appTenant } // Use selectedTenant
      });
      setClearedDebits(response.data || []);
      
      // Extract distinct tenants for filtering
  
    } catch (error) {
      console.error('Error fetching debits:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const tenants = [...new Set(clearedDebits.map(debit => debit.tenantName))]; // Extract unique tenant names
    setDistinctTenants(tenants);
  }, [clearedDebits]); // Run this effect whenever `clearedDebits` changes
  

  const calculateBillingSummary = (clearedDebits) => {
    const tenantSummary = {};

    clearedDebits.forEach((debit) => {
      const { tenantName, totalDebitAmount, user_id } = debit;

      // If tenant doesn't exist in summary, initialise it
      if (!tenantSummary[tenantName]) {
        tenantSummary[tenantName] = {
          totalDebits: 0,  // Sum of all debit amounts
          numberOfDebits: 0,  // Total number of debits
          uniqueUsers: new Set(),  // Unique user IDs to calculate per-user average
        };
      }

      // Update tenant summary
      tenantSummary[tenantName].totalDebits += totalDebitAmount;
      tenantSummary[tenantName].numberOfDebits += 1;
      tenantSummary[tenantName].uniqueUsers.add(user_id); // Add unique user ID
    });

    // Process each tenant to calculate averages
    return Object.entries(tenantSummary).map(([tenantName, tenantData]) => {
      const totalUsers = tenantData.uniqueUsers.size;
      const averageDebitPerUser = tenantData.totalDebits / totalUsers;
      const averageDebitAmount = tenantData.totalDebits / tenantData.numberOfDebits;

      // Special logic for ROUNDA-STAGE tenant

      return {
        tenantName,
        totalDebits: tenantData.totalDebits / 100,  // Convert from cents to dollars
        totalUsers,
        averageDebitPerUser: averageDebitPerUser / 100,  // Convert to dollars
        averageDebitAmount: averageDebitAmount / 100,  // Convert to dollars
      };
    });
  };






  const exportToExcel = () => {
    const userRole = localStorage.getItem('role');
    let filteredDataForExport;

    if (userRole === 'ADMIN' || userRole === 'ROUNDA') { 
    filteredDataForExport = filteredDebits.map(debit => ({
      user_id: debit.user_id,
      tenantName: debit.tenantName,
      email: debit.email,
      totalDebitAmount: debit.totalDebitAmount / 100, // Convert from cents to dollars
      supportingClubId: debit.upportingClubId,
      savingsAmount: debit.savingsAmount / 100,
      clubAmount: debit.clubAmount / 100,
      roundaAmount: debit.roundaAmount /100      
    }));
  } else {
    filteredDataForExport = filteredDebits.map(debit => ({
      user_id: debit.user_id,
      tenantName: debit.tenantName,
      email: debit.email,
      totalDebitAmount: debit.totalDebitAmount / 100, // Convert from cents to dollars
    }));
  }
  
    // Generate the Excel sheet with the filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Debits');
    XLSX.writeFile(workbook, 'FilteredDebits.xlsx');
  };

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
        <MainNav />
        <Box sx={{ padding: '20px', marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',   transition: 'margin-left 0.3s' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
            <Typography variant="h2" sx={{ flexGrow: 1 }}>Last Months Billing</Typography>
          </Box>

          <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tenant Name</TableCell>
              <TableCell>Total Users</TableCell>
              <TableCell>User Fees</TableCell>
              <TableCell>Total Direct Debits ($)</TableCell>
              <TableCell>Average Amount per User ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingSummary.map((summary, index) => (
              <TableRow key={index}>
                <TableCell>{summary.tenantName}</TableCell>
                <TableCell>{summary.totalUsers}</TableCell>
                <TableCell>{formatCurrency(summary.totalUsers) * 2.20 }</TableCell>
                <TableCell>{formatCurrency(summary.totalDebits)}</TableCell>
                <TableCell>{formatCurrency(summary.averageDebitPerUser)}</TableCell>
   
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <TextField
              label="Search Debits"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ flexGrow: 1, marginRight: '10px' }}
            /><FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Filter by Tenant</InputLabel>
            <Select
              value={selectedTenant}
              onChange={(e) => setSelectedTenant(e.target.value)}
              label="Filter by Tenant"
            >
              <MenuItem value="">All Tenants</MenuItem> {/* Default option to show all tenants */}
              {distinctTenants.map((tenantName, index) => (
                <MenuItem key={index} value={tenantName}>
                  {tenantName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    
          </Box>
          <Box align="right" sx={{ mb: 2, ml: 5 }}>
            <Button variant="text" color="primary" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
          </Box>
        
          <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'tenantName'}
                      direction={sortConfig.key === 'tenantName' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('tenantName')}
                    >Tenant Name </TableSortLabel>
                  </TableCell>
                  <TableCell>userID</TableCell>
                 
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'email'}
                      direction={sortConfig.key === 'email' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('email')}
                    >Email </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'totalDebitAmount'}
                      direction={sortConfig.key === 'totalDebitAmount' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('totalDebitAmount')}
                    >Amount</TableSortLabel>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : filteredDebits.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography>No transactions found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredDebits.map((debit) => (
                    <TableRow key={debit.user_id} onClick={() => handleRowClick(debit.user_id)} sx={{ cursor: 'pointer' }}>
                      <TableCell>{debit.tenantName}</TableCell>
                      <TableCell>{debit.user_id}</TableCell>
                      <TableCell>{debit.email}</TableCell>
                      <TableCell>{formatCurrency(debit.totalDebitAmount / 100)}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>

          </Table>
        </TableContainer>
      </Box></ThemeProvider>
    </Container>
  );
};

export default BillingPage;
