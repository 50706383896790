import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/usersSlice';  // Import the users slice
import roleReducer from './slices/rolesSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    role: roleReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',  
});

export default store;