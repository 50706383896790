import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import { Button, Container, Box, Typography, Card, CardContent, CircularProgress, Grid2, ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import { api } from '../services/apis';
import { matchHeaders, mainTheme } from '../services/settings';
import MainNav from '../components/navigation/MainNav';

const UserDetails = () => {
  const { userId } = useParams(); // Get the userId from the URL
  const [user, setUser] = useState(null);
  const theme = mainTheme;
  const userRole = localStorage.getItem('role');
  const [ debitHistoryByMonth, setDebitHistoryByMonth] = useState([]);
  const [ totalDebitAmount, setTotalDebitAmount] = useState([]);
  const [ sortedDebitHistory, setSortedDebitHistory] = useState([]);
  const navigate = useNavigate(); // Use navigate for navigation
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  useEffect(() => {
    fetchUserDetails();
    }, []);

  useEffect(() => {
     fetchTotalDebits();
  }, []);

  const fetchUserDetails = async () => {
    try {
        const headers = await matchHeaders(userRole);
        const response = await axios.get(`${api.retrieveUsers}?cdrid=${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };


  const handleDeactivateUser = () => {
    deactiveUser()
  };

  const deactiveUser = async () => {
    const cdrid = userId;
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.post(api.deactivate, 
        { cdrid: cdrid },
      {
      headers: {
        'Content-Type': 'application/json',
        apikey: headers.apikey,
        tenant: headers.appTenant,
        tenantA: headers.authTenant,
      }
    });
    if (response.status === 200 || response.status === 201) { // Check if the response is successful
      alert('User successfully deactivated!');
      navigate('/users')
    }

  } catch (error) {
      console.error('Error deactivating user:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data); // Additional logging for response error
      }
      alert('Error deactivating user. Please try again.');
    }
  };

  const fetchTotalDebits = async () => {
    try {
        const headers = await matchHeaders(userRole);
        const response = await axios.post(api.retrieveTotalDebits, 
          { cdrid: userId },
        {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
        const sortedDebits = sortDebitsByDate(response.data.debitHistory.documents);
        setTotalDebitAmount(response.data.totalDebitAmount);
        setSortedDebitHistory(sortedDebits);

      
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const sortDebitsByDate = (debits) => {
    return debits.sort((a, b) => {
      const [aMonth, aYear] = a.monthYear.split('-');
      const [bMonth, bYear] = b.monthYear.split('-');
      const aDate = new Date(`20${aYear}-${aMonth}-01`);
      const bDate = new Date(`20${bYear}-${bMonth}-01`);
      return bDate - aDate; // Sort in descending order
    });
  };
  


  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xxl">
      <MainNav /> <ThemeProvider theme={mainTheme}><CssBaseline />
      <Box
         sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',          transition: 'margin-left 0.3s', // Smooth transition when screen size changes
        }}
      >
        <Typography variant="h4" gutterBottom>User Details</Typography>

        {/* Card for user details */}
        <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4}>
          <CardContent>
            <Grid2 container spacing={3}>
              <Grid2 item xs={12} md={6}>
                <Typography variant="body1"><strong>ID:</strong> {user._id}</Typography>
                <Typography variant="body1"><strong>First Name:</strong> {user.firstName}</Typography>
                <Typography variant="body1"><strong>Last Name:</strong> {user.lastName}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
            
         
                <Typography variant="body1"><strong>Tenant:</strong> {user.tenant}</Typography>
                <Typography variant="body1"><strong>Onboarded:</strong> {user.onboardingComplete ? '✔' : '✘'}</Typography>
                <Typography variant="body1"><strong>Consent:</strong> {user.isConsent ? '✔' : '✘'}</Typography>
                <Typography variant="body1"><strong>Created Date:</strong> {new Date(user._createdDate).toLocaleDateString()}</Typography>
            
         
            </Grid2> </Grid2>

            {/* Conditional Fields */}
            <Grid2 container spacing={3} sx={{ marginTop: '20px' }}>
              {user.externalUserId && (
                <Grid2 item xs={12} md={6}>
                  <Typography variant="body1"><strong>External UserId:</strong> {user.externalUserId}</Typography>
                </Grid2>
              )}
              {user.metadata?.ssoExternalUserId && (
                <Grid2 item xs={12} md={6}>
                  <Typography variant="body1"><strong>SSO UserId:</strong> {user.metadata.ssoExternalUserId}</Typography>
                </Grid2>
              )}
            </Grid2>
          </CardContent>
        </Box>
        <Typography variant="h4" mt={1} sx={{ marginTop: '20px' }}>
              Donation History
            </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
      <Grid2 container alignItems="center">
        <Box display="flex" alignItems="center" width="100%" mt='10px'>  
          <Grid2 item xs={8}>
           
          </Grid2>
          <Grid2 item xs={4} container justifyContent="flex-end">
           
          </Grid2>
        </Box>            
      </Grid2>

      {sortedDebitHistory.map((debit, index) => (
        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" width="100%" mt='10px'>
          <Typography variant="body2">
            {debit.monthYear}:
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'right', flexGrow: 1 }}>
            ${((debit.totalDebitAmount / 100).toFixed(2))}
          </Typography>
        </Box>
      ))}
    </Box>

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', marginTop: '40px', gap: '10px', justifyContent: 'right' }}>
          <Button variant="contained"  onClick={handleDeactivateUser} sx={{ backgroundColor: theme.palette.action.delete }}>Deactivate</Button>
    
        </Box>
      </Box></ThemeProvider>
    </Container>
  );
};

export default UserDetails;
