import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, CircularProgress, Box, useMediaQuery, ThemeProvider, CssBaseline } from '@mui/material';
import { api } from '../services/apis';
import { matchHeaders, formatCurrency, mainTheme } from '../services/settings';
import MainNav from '../components/navigation/MainNav';

const InvoiceDetailsPage = () => {
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { invoiceId } = useParams(); // Get the invoiceId from the URL
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = localStorage.getItem('role');
  console.log(invoiceId);

  useEffect(() => {
    console.log(invoiceId);
    fetchInvoiceDetails();
  }, []);

  const fetchInvoiceDetails = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(`${api.getInvoices}?invoiceId=${invoiceId}`, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
      });
      const sortedInvoices = response.data.Invoices.sort((a, b) => new Date(b.DateString) - new Date(a.DateString));
      setInvoice(sortedInvoices); // Set the invoice details
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xxl">
     <ThemeProvider theme={mainTheme}><CssBaseline />
      <MainNav />
      <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
            marginTop: '30px', 
            transition: 'margin-left 0.3s',
          }}
> {/* Ensure marginLeft for MainNav */}
        <Typography variant="h4" gutterBottom>Invoice Details</Typography>

      {loading ? (
        <CircularProgress />
      ) : invoice ? (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Invoice Number: {invoice[0].InvoiceNumber}</Typography>
          <Typography>Date Issued: {new Date(invoice[0].DateString).toLocaleDateString()}</Typography>
          <Typography>Due Date: {new Date(invoice[0].DueDateString).toLocaleDateString()}</Typography>
          <Typography>Total: {formatCurrency(invoice[0].Total)}</Typography>
          <Typography>Sent: {invoice[0].SentToContact}</Typography>
          <Typography>Status: {invoice[0].Status}</Typography>
          <Typography>Amount: {formatCurrency(invoice[0].AmountDue)}</Typography>
          <Typography>Amount Paid: {formatCurrency(invoice[0].AmoutPaid)}</Typography>


          {/* Add more details as required */}
        </Box>
      ) : (
        <Typography>No invoice details found.</Typography>
      )}
      </Box></ThemeProvider>
    </Container>
  );
};

export default InvoiceDetailsPage;
