import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl, Typography, useMediaQuery, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../services/settings';
import * as XLSX from 'xlsx';


const Transactions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [directDebitTransactions, setDirectDebitTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true); // Track if more pages are available
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '_createdDate', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]);
  const [distinctStatuses, setDistinctStatuses] = useState([]);
  const userRole = localStorage.getItem('role');
  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    fetchTransactions(1);  // Load the first page when component mounts
  }, []);

  const fetchTransactions = async (page) => {
    try {
      setIsLoading(true);
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveDirectDebitsAll, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
        params: {
          page: page,    // Current page
          limit: 100,    // 100 transactions per page
        },
      });

      const transactions = response.data.documents || [];
      
      // Check if there are no more pages to load
      if (transactions.length < 100) {
        setHasMorePages(false);
      }

      // Append new transactions to the existing state
      setDirectDebitTransactions((prevTransactions) => [
        ...prevTransactions,
        ...transactions,
      ]);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadNextPage = () => {
    const nextPage = currentPage + 1;
    fetchTransactions(nextPage);
    setCurrentPage(nextPage);  // Update current page number
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    // Sort transactions based on the selected key and direction
    const sortedTransactions = [...directDebitTransactions].sort((a, b) => {
      if (key === '_createdDate') {
        return direction === 'asc'
          ? new Date(a._createdDate) - new Date(b._createdDate)
          : new Date(b._createdDate) - new Date(a._createdDate);
      }
      return a[key] < b[key] ? -1 : 1;
    });

    setDirectDebitTransactions(sortedTransactions);
  };

  // Export to Excel
  const exportToExcel = (sortedTransactions) => {
    const worksheet = XLSX.utils.json_to_sheet(sortedTransactions); // Convert JSON data to sheet format
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions'); // Append sheet to workbook
    XLSX.writeFile(workbook, 'TransactionsData.xlsx'); // Generate Excel file and download
  };

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px',
            marginTop: '30px',
            transition: 'margin-left 0.3s',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
            <Typography variant="h2" sx={{ flexGrow: 1 }}> Transactions </Typography>
          </Box>

          {/* Filters */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <TextField
              label="Search Transactions"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ flexGrow: 1, marginRight: '10px' }}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Filter by Tenant</InputLabel>
              <Select
                value={selectedTenant}
                onChange={(e) => setSelectedTenant(e.target.value)}
                label="Filter by Tenant"
              >
                <MenuItem value="">All Tenants</MenuItem>
                {distinctTenants.map((tenant, index) => (
                  <MenuItem key={index} value={tenant}>
                    {tenant}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                label="Filter by Status"
              >
                <MenuItem value="">All Statuses</MenuItem>
                {distinctStatuses.map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box align="right" sx={{ mb: 2 }}>
            <Button variant="outlined" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
          </Box>

          {/* Table */}
          <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
          <TableCell>
              <TableSortLabel
                active={sortConfig.key === '_createdDate'}
                direction={sortConfig.direction}
                onClick={() => requestSort('_createdDate')}
              >
                Created Date
              </TableSortLabel>
            </TableCell>
           
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === 'tenantName'}
                direction={sortConfig.direction}
                onClick={() => requestSort('tenantName')}
              >
                Tenant
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === 'status'}
                direction={sortConfig.direction}
                onClick={() => requestSort('status')}
              >
                Status
              </TableSortLabel>
            </TableCell>
          
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === 'totalDebitAmount'}
                direction={sortConfig.direction}
                onClick={() => requestSort('totalDebitAmount')}
              >
                Amount
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && currentPage === 1 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : directDebitTransactions.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography>No transactions found.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            directDebitTransactions.map((transaction) => (
              <TableRow key={transaction._id}>
                <TableCell>{new Date(transaction._createdDate).toLocaleDateString()}</TableCell>
                <TableCell>{transaction.tenantName}</TableCell>
                <TableCell>{transaction.status}</TableCell>
                <TableCell>{formatCurrency(transaction.totalDebitAmount / 100)}</TableCell>

      
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* Show Load More button if there are more pages */}
      {hasMorePages && !isLoading && (
        <Box align="center" sx={{ padding: 2 }}>
          <Button onClick={loadNextPage} variant="outlined" disabled={isLoading} sx={{backgroundColor: theme.palette.action.good }}>
            {isLoading ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default Transactions;
