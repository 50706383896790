import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBVfwwzf_ok1UFpoI7EDFMECkZMvQvco-Y",
    authDomain: "admin-7c4ae.firebaseapp.com",
    projectId: "admin-7c4ae",
    storageBucket: "admin-7c4ae.appspot.com",
    messagingSenderId: "209746640178",
    appId: "1:209746640178:web:70bffb2ee02fbdb81feb2b"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);