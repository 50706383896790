const URL = `https://minerva.sipora.io/api`

export const api = {
    retrieveUsers: `${URL}/client/admin/retrieve-users`,
    totalRoundups: `${URL}/client/admin/total-roundups`,
    retrieveFloats: `${URL}/admin/retrieve-floats`,
    retrieveNrmaFloat: `${URL}/handlers/zepto/nrma-float-status`,
    retrieveNrmaTopUp: `${URL}/handlers/zepto/nrma-float-assess`,
    retrievePokitPalTopUp: `${URL}/handlers/zepto/pokitpal-float-assess`,
    retrievePokitPalFloat: `${URL}/handlers/zepto/pokitpal-float-status`,
    retrieveRoundUpFloat: `${URL}/handlers/zepto/roundup-float-status`,
    retrieveDirectDebitsAll: `${URL}/admin/retrieve-directdebits`,
    eomBillingReporting: `${URL}/reporting/eom-reporting`,
    consent: `${URL}/client/round-up/user/consent`,
    deactivate: `${URL}/client/round-up/user/deactivate`,
    accounts: `${URL}/client/round-up/user/accounts`,
    manageConsent: `${URL}/client/round-up/user/manage-consent`,
    getUser: `${URL}/client/round-up/user/get-user`,
    retrieveTotalDebits: `${URL}/client/round-up/user/retrieve-total-debits`,
    retrieveHistory: `${URL}/client/round-up/user/retrieve-history`,
    setRoundUpAmount: `${URL}/client/round-up/user/set-roundup-amount`,
    setWeeklyLimit:  `${URL}/client/round-up/user/set-weekly-limit`,
    listClubNames: `${URL}/clubs/retrieve`,
    registerClub: `${URL}/clubs/register`,
    findClub: `${URL}/clubs/club`,
    retrieveClubs: `${URL}/client/admin/retrieve-clubs`,
    retrieveLinkedSavings: `${URL}/client/round-up/user/linked-savings`,
    submitSavingsAccount: `${URL}/client/round-up/user/submit-savings-account`,
    setSavingsAccount: `${URL}/client/round-up/user/set-savings-account`,
    retrieveAccounts: `${URL}/client/round-up/user/retrieve-accounts`,
    updateRoundUpAccounts: `${URL}/client/round-up/user/update-roundup-accounts`,
    setRoundUpAccounts: `${URL}/client/round-up/user/set-roundup-accounts`,
    setPaused: `${URL}/client/round-up/user/set-pause`,
    donateNow: `${URL}/client/round-up/user/donate-now`,
    updateFundingAccount: `${URL}/client/round-up/user/update-funding-account`,
    checkJob: `${URL}/client/round-up/user/check-job`,
    onboardingComplete: `${URL}/client/round-up/user/onboarding-complete`,
    registerFunding: `${URL}/client/round-up/user/register-funding`,
    findJobs: `${URL}/handlers/basiq/find-jobs`,
    getInvoices: `${URL}/handlers/xero/invoices`,
  
  }