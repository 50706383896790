import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Box, useMediaQuery, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/People';
import ApartmentOutlinedIcon from '@mui/icons-material/Apartment';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LOGO from '../../assets/PokitPal-Logo.jpg'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { useSelector } from 'react-redux';

const drawerWidth = 300; // Slightly wider for better layout

const MainNav = () => {
  const userRole = useSelector((state) => state.role.role);
  console.log('user_role:', userRole);
  const [open, setOpen] = useState(false);
  const [openClubs, setOpenClubs] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Use navigate for navigation


  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  const toggleClubs = () => {
    setOpenClubs(!openClubs);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <HomeOutlinedIcon />, link: '/dashboard' },
    { text: 'Users', icon: <PeopleOutlinedIcon />, link: '/users' },
    ...(userRole === 'ADMIN' ? [{ text: 'Tenants', icon: <ApartmentOutlinedIcon />, link: '/tenants' }] : []),
    ...(userRole === 'ROUNDA' || userRole === 'ADMIN' ? [
      {
        text: 'Clubs', icon: <Diversity3OutlinedIcon />, link: '/clubs',
        children: [
          {text: 'Manage', icon: <ListAltOutlinedIcon />, link: '/clubs'},
          { text: 'New Club', icon: <AddOutlinedIcon />, link: '/clubs/new'}
        ]
      }
    ] : []),
    ...(userRole === 'ADMIN' ? [ { text: 'Transactions', icon: <AttachMoneyOutlinedIcon />, link: '/transactions' }] : []),
    ...(userRole === 'ADMIN' ? [ { text: 'Billing', icon: <AttachMoneyOutlinedIcon />, link: '/billing' }] : []),
    ...(userRole === 'ADMIN' ? [{ text: 'Floats', icon: <AccountBalanceOutlinedIcon />, link: '/floats' }] : []),
    { text: 'Settings', icon: <SettingsOutlinedIcon />, link: '/profile' },
    { text: 'Logout', icon: <LogoutOutlinedIcon />, link: '/logout' },
  ];

  const drawerContent = (
    <Box sx={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)', borderRadius: '12px', mt: '50px', ml: '10px'}}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
       <img src={LOGO} width='240px'></img>
      </Typography>
      <List sx={{ mt: 6 }}>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
  button
  onClick={(e) => {
    if (item.children) {
      e.preventDefault(); // Prevent navigation
      toggleClubs();      // Only toggle submenu
    } else {
      navigate(item.link); // Manually navigate to the link if no children
    }
  }}
  sx={{
    '&:hover': {
      backgroundColor: '#EEF2FB', // Hover background
    },
    color: '#3F4254',
    px: 3,
  }}
>
  <ListItemIcon sx={{ color: '#3F4254', minWidth: 40 }}>{item.icon}</ListItemIcon>
  <ListItemText primary={item.text} />
  {item.children && (openClubs ? <ExpandLess /> : <ExpandMore />)}
</ListItem>
            {item.children && (
              <Collapse in={openClubs} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child, childIndex) => (
                    <ListItem
                      button
                      key={childIndex}
                      sx={{ pl: 6, backgroundColor: '#EEF2FB' }} // Submenu background color
                      component={RouterLink}
                      to={child.link}
                    >
                      <ListItemIcon sx={{ color: '#3F4254', minWidth: 40 }}>{child.icon}</ListItemIcon>
                      <ListItemText primary={child.text} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {isMobile && (
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        variant={isMobile ? 'temporary' : 'permanent'}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            marginRight: '20px',
            boxSizing: 'border-box',
            backgroundColor: '#f5f6fa', // Light background
            borderRight: 'none', // Remove border if desired
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default MainNav;
