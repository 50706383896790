import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
  name: 'role',
  initialState: {
    role: null,
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload; 
    }
  },
});

export const { setRole } = rolesSlice.actions;

export default rolesSlice.reducer;
