import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Grid2, Card, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, ThemeProvider, CssBaseline, Tabs, Tab  } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../services/settings';
import { useTheme } from '@mui/material/styles';
import { setUsers } from '../redux/slices/usersSlice';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import {  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';



const DashboardNew = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.role.role);
  const users = useSelector((state) => state.users.users);
  const [roundUpsIsLoading, setRoundUpsIsLoading] = useState(true);
  const [usersIsLoading, setUsersIsLoading] = useState(true);
  const [loadingClubs, setLoadingClubs] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartData, setChartData] = useState([]);
  const [chartDataTransactions, setChartDataTransactions] = useState([]);
  const [clubCount, setClubCount] = useState('');
  const [usersByMonth, setUsersByMonth] = useState([]);
  const [totalMaturing, setTotalMaturing] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'firstName', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedConsent, setSelectedConsent] = useState('');
  const [selectedOnboarded, setSelectedOnboarded] = useState('');
  const [selectedPaused, setSelectedPaused] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]);
  const [distinctConsents, setDistinctConsents] = useState([]);
  const [distinctOnboarded, setDistinctOnboarded] = useState([]);
  const [distinctPaused, setDistinctPaused] = useState([]);
  const [totalRoundUps, setTotalRoundUps] = useState('');
  const [inMonthRoundUps, setInMonthRoundUps] = useState('');
  const [lastMonthRoundUps, setLastMonthRoundUps] = useState('');
  
 


    
  const navigate = useNavigate(); // Use navigate for navigation



  useEffect(() => {
    const tenants = [...new Set(users.map(user => user.tenant))];
    setDistinctTenants(tenants);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapOnboarded(user.onboardingComplete)))];
    setDistinctConsents(consents);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapConsent(user.isConsent)))];
    setDistinctConsents(consents);
  }, [users]);

  useEffect(() => {
    const paused = [...new Set(users.map(user => mapPaused(user.isUserPausedRoundUp)))];
    setDistinctPaused(paused);
  }, [users]);


 

  // Mapping consent values to "✔" and "✘"
  const mapConsent = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapPaused = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapOnboarded = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  // Sorting function
  const sortedUsers = [...users].sort((a, b) => {
    const valueA = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
    const valueB = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

    if (valueA < valueB) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Filter users based on the search query and selected tenant
  const filteredUsers = sortedUsers.filter(user => {
    const firstName = user.firstName ? user.firstName.toLowerCase() : '';
    const lastName = user.lastName ? user.lastName.toLowerCase() : '';
    const email = user.email ? user.email.toLowerCase() : '';
    const tenant = user.tenant ? user.tenant.toLowerCase() : '';
    const consent = mapConsent(user.isConsent);
    const onboarded = mapOnboarded(user.onboardingComplete);
    const paused = mapPaused(user.isUserPausedRoundUp);

    return (
      (firstName.includes(searchQuery.toLowerCase()) ||
        lastName.includes(searchQuery.toLowerCase()) ||
        email.includes(searchQuery.toLowerCase()) ||
        tenant.includes(searchQuery.toLowerCase())) &&
      (selectedTenant === '' || user.tenant === selectedTenant) &&
      (selectedConsent === '' || consent === selectedConsent) &&
      (selectedOnboarded === '' || onboarded === selectedOnboarded) &&
      (selectedPaused === '' || paused === selectedPaused)
    );
  });

  const totalusers = users.length;
  const consentedUsersCount = filteredUsers.filter(user => user.isConsent === true).length;
  const onboardedUsersCount = filteredUsers.filter(user =>  user.onboardingComplete === true).length;
  const pausedUsersCount = filteredUsers.filter(user =>  user.isUserPausedRoundUp === true).length;
  const activeUsers = onboardedUsersCount - pausedUsersCount;

  

  // Change sort direction and key
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Navigate to user details page
  const handleRowClick = (userId) => {
    navigate(`/users/${userId}`);
  };

   
  useEffect(() => {
    if (userRole) {
     fetchTotalRoundUps(userRole);
     fetchUsers(userRole);

     if (userRole ==='ADMIN' || userRole ==='ROUNDA') {
      fetchClubList();
      }

    }
  }, [userRole]);



 const fetchClubList = async () => {
  try {
          const headers = await matchHeaders(userRole);
          const response = await axios.get(api.listClubNames, {
            headers: {
              'Content-Type': 'application/json',
              apikey: headers.apikey,
              tenant: headers.appTenant,
              tenantA: headers.authTenant,
            }
          });

    const clubs = response.data.clubs;
    const clubCount = clubs.length;
    setClubCount(clubCount);
  } catch (error) {
    console.error('Error fetching club list:', error);
  } finally {
    setLoadingClubs(false);    
}};

  const fetchUsers = async (headers) => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveUsers, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });

      const allUsers = response.data;
      dispatch(setUsers(allUsers));

      const aggregateData = aggregateUsersByMonth(allUsers);
      setChartData(aggregateData);
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUsersIsLoading(false);
    }
  };

  const fetchTotalRoundUps = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.totalRoundups, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      
      //const maturingAmount = response.data.summary.getMaturingDebits;   
      // Example usage:
      const transactionForChart = transformData(response.data.documents);
      setChartDataTransactions(transactionForChart);
      

      const totalAmount = response.data.documents.reduce((acc, curr) => {
        return acc + curr.totalDebitAmount;
      }, 0) / 100;
      

      const getCurrentMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Pad the month with a leading zero if needed
        return `${year}-${month}`; // Format: YYYY-MM
      };
      
      const currentMonth = getCurrentMonth(); // Get the current month
      
      const monthAmount = response.data.documents
        .filter((doc) => doc.month === currentMonth) // Filter by the current month
        .reduce((acc, curr) => {
          return acc + curr.totalDebitAmount;
        }, 0) / 100; // Convert to dollars
      
      console.log(monthAmount); // This will give you the total for the current month

      

      const getPreviousMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth()).toString().padStart(2, '0'); // Pad the month with a leading zero if needed
        return `${year}-${month}`; // Format: YYYY-MM
      };
      
      const previousMonth = getPreviousMonth(); // Get the current month
      
      const lastMonthAmount = response.data.documents
        .filter((doc) => doc.month === previousMonth) // Filter by the current month
        .reduce((acc, curr) => {
          return acc + curr.totalDebitAmount;
        }, 0) / 100; // Convert to dollars
      
      console.log(lastMonthAmount); // This will give you the total for the current month

      
      
      setTotalRoundUps(totalAmount); 
      setInMonthRoundUps(monthAmount);
      setLastMonthRoundUps(lastMonthAmount);
//      setTotalMaturing(maturingAmount);

    } catch (error) {
      console.error('Error fetching debits:', error);
    } finally {
      setRoundUpsIsLoading(false);
    }
    }
  

    const transformData = (data) => {
      const formattedData = [];
    
      data.forEach((item) => {
        // Check if this month already exists in the formattedData array
        let monthEntry = formattedData.find((entry) => entry.month === item.month);
    
        if (!monthEntry) {
          // If the month doesn't exist, create a new entry for this month
          monthEntry = { month: item.month };
          formattedData.push(monthEntry);
        }
    
        // Add or update the totalDebitAmount for the tenantName in the existing or new month entry
        monthEntry[item.tenantName] = item.totalDebitAmount / 100;
      });
    
      return formattedData;
    };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users); // Convert JSON data to sheet format
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users'); // Append sheet to workbook
    XLSX.writeFile(workbook, 'UsersData.xlsx'); // Generate Excel file and download
  };

  const defaultShadow = '0 2px 14px 0 rgb(32 40 45 / 8%)';

  const aggregateUsersByMonth = (users) => {
    const usersByMonth = {};
  
    // Get the current year
    const currentYear = new Date().getFullYear();
  
    // First, group the users by month
    users.forEach((user) => {
      if (user._createdDate) {
        const createdDate = new Date(user._createdDate);
    
        // Only include users created in the current year
      
          const yearMonth = format(createdDate, 'yyyy-MM'); // Format to "YYYY-MM"
  
          if (!usersByMonth[yearMonth]) {
            usersByMonth[yearMonth] = 0;
          }
  
          usersByMonth[yearMonth] += 1;
  
      }
    });
  
    // Convert the grouped data into a cumulative format
    const sortedMonths = Object.keys(usersByMonth).sort(); // Sort by month (keys)
    let cumulativeTotal = 0;
  
    const cumulativeData = sortedMonths.map((month) => {
      cumulativeTotal += usersByMonth[month]; // Add to cumulative total
      return { month, count: cumulativeTotal }; // Return the cumulative value for each month
    });
  
    return cumulativeData; // This will be used as chart data
  };
  

  const dashboardCardsCounts = [
    { title: 'Total Users', value: users.length, icon: <PeopleIcon fontSize="large" />, color: '#058efc', isLoading: usersIsLoading },
    { title: 'Consented Users', value: consentedUsersCount, icon: <CheckCircleIcon  fontSize="large" />, color: '#733aea',isLoading: usersIsLoading },
    { title: 'Onboard Complete', value: onboardedUsersCount, icon: <PeopleIcon fontSize="large" />, color: '#fd9722',isLoading: usersIsLoading },
    { title: 'Active Users', value: activeUsers, icon: <PeopleIcon fontSize="large" />,color: '#f2426e', isLoading: usersIsLoading },
    { title: 'Paused Users', value: pausedUsersCount, icon: <PeopleIcon fontSize="large" />, color: '#058efc',isLoading: usersIsLoading },
    ...(userRole === 'ROUNDA' || userRole === 'ADMIN' ? [  { title: 'Clubs', value: clubCount, icon: <GroupIcon  fontSize="large" />,color: '#f2426e', isLoading: loadingClubs }] : []),
  ];

  const dashboardCardsValues = [
    { title: 'This Month RoundUps', value: formatCurrency(inMonthRoundUps), color: '#058efc',isLoading: roundUpsIsLoading },
    { title: 'Last Month RoundUps', value: formatCurrency(lastMonthRoundUps),  color: '#733aea',isLoading: roundUpsIsLoading },
    { title: 'Total RoundUps', value: formatCurrency(totalRoundUps),  color: '#fd9722',isLoading: roundUpsIsLoading }

    // { title: 'Total RoundUps', value: formatCurrency(totalRoundUps), icon: <AttachMoneyIcon fontSize="large" />, isLoading: usersIsLoading }
  ];

 
  return (
    <Container maxWidth="xxl">
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <MainNav />
      <Box
        sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '50px',
          transition: 'margin-left 0.3s', // Smooth transition when screen size changes
        }}
      >
        <Typography variant="h2" gutterBottom>
          Dashboard
        </Typography>

        {/* Grid for dashboard cards */}
        <Grid2 container spacing={3} mb={6}>
          {dashboardCardsCounts.map((card, index) => (
            <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  width: 300, // Ensures the card takes up the full width of the grid item
                  height: 150,   // Fixed height for all cards
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: defaultShadow,
                  borderRadius: '16px',
                  backgroundColor: card.color, // Card-specific color
                  color: '#fff', // White text for better contrast
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  {card.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {card.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {card.icon}
                        <Typography variant="h4" sx={{ ml: 1 }}>
                          {card.value}
                        </Typography>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>

        <Grid2 container spacing={3} mb={6}>
          {dashboardCardsValues.map((card, index) => (
            <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
               sx={{
                width: 300, // Ensures the card takes up the full width of the grid item
                height: 150,   // Fixed height for all cards
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: defaultShadow,
                borderRadius: '16px',
                backgroundColor: card.color, // Card-specific color
                color: '#fff', // White text for better contrast
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  {card.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {card.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {card.icon}
                        <Typography variant="h4" sx={{ ml: 1 }}>
                          {card.value}
                        </Typography>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>

        {/* Line Chart */}
        <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">User Growth month on month</Typography>
    {/*    <Tabs value={timeframe} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Week" value="Week" />
          <Tab label="Month" value="Month" />
        </Tabs> */}
      </Box>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />  {/* Light grey grid */}
          <XAxis dataKey="month" stroke="#888" /> {/* Grey axis lines */}
          <YAxis stroke="#888" />
          <Tooltip />
          <Legend
            align="right"
            verticalAlign="top"
            iconType="circle"
          />
          
          {/* Add multiple lines with custom colours */}
          <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} />
  {/*        <Line type="monotone" dataKey="Google Ads" stroke="#ffa726" strokeWidth={2} />
          <Line type="monotone" dataKey="Courses" stroke="#ff7043" strokeWidth={2} />
          <Line type="monotone" dataKey="Email" stroke="#66bb6a" strokeWidth={2} /> */}
        </LineChart>
      </ResponsiveContainer>
    </Box>
    {userRole === 'ADMIN' && (
    <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Monthly Direct Debits by Tenant</Typography>
    {/*    <Tabs value={timeframe} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Week" value="Week" />
          <Tab label="Month" value="Month" />
        </Tabs> */}
      </Box>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartDataTransactions}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />  {/* Light grey grid */}
          <XAxis dataKey="month" stroke="#888" /> {/* Grey axis lines */}
          <YAxis stroke="#888" />
          <Tooltip />
          <Legend
            align="right"
            verticalAlign="top"
            iconType="circle"
          />
          
          {/* Add multiple lines with custom colours */}
          <Line type="monotone" dataKey="CENTS2BILLS-PROD" stroke="#8884d8" strokeWidth={2} />
        <Line type="monotone" dataKey="ROUNDA-STAGE" stroke="#82ca9d" strokeWidth={2} />
        <Line type="monotone" dataKey="POKITPAL-PROD" stroke="#ffc658" strokeWidth={2} />
        <Line type="monotone" dataKey="BOOST-YOUR-SUPER-PROD" stroke="#ff7300" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box> )}
      </Box>
    </ThemeProvider>
  </Container>
);
};


export default DashboardNew;