import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Container,
  Typography,
  useMediaQuery,
  CircularProgress,
  ThemeProvider,
  CssBaseline,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
} from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../services/settings';
import { useTheme } from '@mui/material/styles';

const InvoicesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userRole = localStorage.getItem('role');
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'Date', direction: 'asc' });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(''); // Status filter state
  const navigate = useNavigate();

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = async () => {
    const isAdmin = localStorage.getItem('role');
    if (isAdmin !== 'ADMIN') {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.getInvoices, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
      });
      const sortedInvoices = response.data.Invoices.sort(
        (a, b) => new Date(b.DateString) - new Date(a.DateString)
      );
      setInvoices(sortedInvoices);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setInvoicesLoading(false);
    }
  };

  // Filter invoices by search query (contact name) and status
  const filteredInvoices = invoices.filter((invoice) => {
    const contactName = invoice.Contact && invoice.Contact.Name ? invoice.Contact.Name.toLowerCase() : '';
    return (
      contactName.includes(searchQuery.toLowerCase()) &&
      (selectedStatus === '' || invoice.Status === selectedStatus)
    );
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setInvoices(
      [...invoices].sort((a, b) => {
        if (direction === 'asc') {
          return new Date(a[key]) - new Date(b[key]);
        } else {
          return new Date(b[key]) - new Date(a[key]);
        }
      })
    );
  };

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
            marginTop: '30px',              transition: 'margin-left 0.3s',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
              marginTop: '40px',
            }}
          >
            <Typography variant="h2" sx={{ flexGrow: 1 }}>
              Invoices
            </Typography>
          </Box>
          <TextField
            label="Search by Contact Name"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ flexGrow: 1, marginRight: '10px', marginBottom: '20px' }}
          />

          {/* Status Dropdown Filter */}
          <FormControl sx={{ minWidth: 200, marginBottom: '20px' }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Statuses</MenuItem>
              <MenuItem value="AUTHORISED">Authorised</MenuItem>
              <MenuItem value="PAID">Paid</MenuItem>
              <MenuItem value="DRAFT">Draft</MenuItem>
              {/* Add more statuses as needed */}
            </Select>
          </FormControl>

          <Box align="right" sx={{ mb: 2 }}>
            <Button
              variant="text"
              color="primary"
              onClick={() => console.log('Export to Excel clicked')}
            >
              Export to Excel
            </Button>
          </Box>

          {/* Table Container */}
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'auto',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'Date'}
                      direction={sortConfig.key === 'Date' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('Date')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoicesLoading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : filteredInvoices.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography>No invoices found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredInvoices.map((invoice) => (
                    <TableRow
                      key={invoice.InvoiceID}
                      onClick={() => navigate(`/invoices/${invoice.InvoiceID}`)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>{invoice.InvoiceNumber}</TableCell>
                      <TableCell>{new Date(invoice.DateString).toLocaleDateString()}</TableCell>
                      <TableCell>{invoice.Contact.Name}</TableCell>
                      <TableCell>{formatCurrency(invoice.Total)}</TableCell>
                      <TableCell>{invoice.Status}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default InvoicesPage;
